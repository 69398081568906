@import "./base/var";
@import "./base/fonts";
@import "./base/container";
@import "./compopnents/btn";
@import "./compopnents/page-top";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-transform: none;
  text-decoration: none;
  font-family: 'Inter', sans-serif;
}

body {
  background-image: linear-gradient(45deg, rgba(255,45,46,1) 0%,rgba(30,30,30,1) 10%,rgba(30,30,30,1) 90%,rgba(30,30,30,1) 90%,rgba(255,45,46,1) 100%);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  position: relative;
  min-height: 100vh;
  overflow-x: hidden;
}

.page-content {
  max-width: 100%;
  margin-bottom: 100px;

  @media (min-width: 992px) {
    max-width: calc(100% - 100px);
    margin-bottom: 0px;
  }
}


@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.rotate {
  animation: rotate 5s linear infinite;
}
