@import "../base/var";

.menu {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  flex-wrap: nowrap;
  position: fixed;
  width: 100%;
  gap: 50px;
  bottom: 0;
  right: 0;
  padding: 20px 10px;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  background: $main-bg-shadow-color;

  @media (min-width: 992px) {
    flex-direction: column;
    gap: 20px;
    top: 50%;
    right: 50px;
    transform: translateY(-50%);
    width: max-content;
    padding: 0;
    height: max-content;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    background: transparent;
  }

  &-item {
    width: 35px;
    height: 35px;
    cursor: pointer;
    border-radius: 50%;

    &.active {
      box-shadow: 0 0 20px 5px #fff;
      background: rgb(255 255 255 / 55%);
    }

    img {
      width: 100%;
      height: auto;
      display: block;
      object-fit: cover;
    }


    @media (min-width: 992px) {
      width: 50px;
      height: 50px;

      &.active {
        box-shadow: 0 0 20px 5px $main-bg-shadow-color;
        background: rgb(255 45 46 / 62%);
      }
    }
  }
}