.leadsTable {
  margin-top: 50px;

  .filters {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 40px;

    .filterDate {
      display: flex;
      flex-direction: column;
      gap: 20px;
      width: 400px;
      max-width: 100%;

      .top, .bottom {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
      }

      .bottom {
        gap: 5px;

        button {
          border: 1px solid #FF2D2E;
          background: #fff;
          padding: 3px 7px;
          border-radius: 5px;
          color: #131313;
          font-weight: 700;
          font-size: 14px;
          cursor: pointer;
        }
      }
    }

    .filterTables {
      select {
        display: block;
        border: 1px solid #FF2D2E;
        padding: 7px;
        outline: none;
        border-radius: 7px;
        width: 100%;
        max-width: 215px;
        color: #131313;
        font-weight: 700;
        font-size: 14px;
      }
    }

    input[type="date"] {
      display: block;
      border: 1px solid #FF2D2E;
      padding: 7px;
      outline: none;
      border-radius: 7px;
      width: 100%;
      max-width: 215px;
      color: #131313;
      font-weight: 700;
      font-size: 14px;
    }
  }

  .table {
    overflow-x: auto;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;

    th {
      background-color: #f2f2f2;
      border: 1px solid #ddd;
      padding: 8px;
      text-align: left;
      white-space: nowrap;
    }

    td {
      border: 1px solid #ddd;
      border-bottom: 1px solid #FF2D2E;
      padding: 8px;
      white-space: nowrap;
    }

    .tdDate {
      text-align: center;
    }

    tr:nth-child(odd) {
      background-color: #f9f9f9;
    }

    tr:nth-child(even) {
      background-color: #f9f9f9;
    }

    .tableTdSpend {
      width: 70px;
      padding: 2px;

      input {
        width: 100%;
      }

    }

    .tableTdName {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 10px;
      cursor: pointer;
    }

    .tableTdFD {
      width: 7%;
    }

    .tableTdROI {
      width: 10%;
    }

    .tableTdNameMain {
      font-size: 16px;
      font-weight: 700;
    }

    .tableTdNameParent {
      p {
        font-size: 14px;
        font-weight: 600;
        margin-left: 20px;
      }
    }

    .tableTdNameChild {
      p {
        font-size: 12px;
        font-weight: 500;
        margin-left: 40px;
      }
    }

    @media (min-width: 1200px) {
      .tableTdNameMain {
        font-size: 18px;
      }

      .tableTdNameParent {
        p {
          font-size: 16px;
        }
      }

      .tableTdNameChild {
        p {
          font-size: 14px;
        }
      }
    }

  }

  .pagination {
    display: flex;
    gap: 20px;
    margin-bottom: 50px;
    margin-top: 20px;
    align-items: center;

    p {
      font-size: 16px;
      font-weight: 600;
      color: #f2f2f2;
    }

    select {
      border: 1px solid #FF2D2E;
      background: #fff;
      padding: 3px 7px;
      border-radius: 5px;
      color: #131313;
      font-weight: 700;
      font-size: 14px;
      cursor: pointer;
    }

  }

}


.empty-table-message {
  color: #666;
  font-style: italic;
}
