.page-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 50px;

  &-title {
    color: $text-light-color;
    font-size: 27px;
    font-weight: 600;
    line-height: 1;
  }

  .logo {
    width: 80px;
    height: auto;
  }

  @media (min-width: 768px) {
    &-title {
      font-size: 32px;
    }
  }

  @media (min-width: 992px) {
    &-title {
      font-size: 42px;
    }

    .logo {
      width: 105px;
    }
  }

  @media (min-width: 1200px) {
    &-title {
      font-size: 56px;
    }
  }

}