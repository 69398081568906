.btn {
  display: inline-block;
  background-color: $main-bg-shadow-color;
  outline: none;
  padding: 15px 50px;
  color: $text-light-color;
  font-weight: 500;
  font-size: 16px;
  border-radius: 7px;
  border: none;
  cursor: pointer;
}