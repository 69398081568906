//Container

$container-padding: .75rem;

// Background | Text

$main-bg-color: #1E1E1E;
$main-bg-shadow-color: #FF2D2E;
$main-bg-light-shadow-color: rgba(255, 45, 46, 0.5);
$text-light-color: #FFFFFF;
$text-dark-color: #131313;
$text-light-dark-color: rgba(19, 19, 19, 0.5);
$text-link-color: #369FFF;