@import "../base/var";

.page-not-found {

  .page-content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50vh;

    h1 {
      font-size: 70px;
      color: #ffffff;

      @media (min-width: 576px) {
        font-size: 120px;
      }
    }

  }

}