@import "../base/var";
@import "../compopnents/form";


.page-login {
  display: flex;
  flex-direction: column;
  align-items: center;

  .logo {
    width: 250px;
    height: auto;
    display: block;
    margin-top: 100px;
    margin-bottom: 50px;

    @media (min-width: 992px) {
      width: 290px;
      margin-bottom: 70px;
    }

    @media (min-width: 1200px) {
      width: 330px;
      margin-bottom: 100px;
    }

    img {
      width: 100%;
      height: auto;
    }

  }

}