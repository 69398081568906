.form {

  &.login-form {
    align-items: center;

    .signup-link {
      margin-top: 10px;
      color: #ff2d2e;
      text-decoration: underline;
      font-size: 18px;
    }
  }

  &.login-form, &.change-pass-form, &.change-account-info-form, &.signup-form {
    display: flex;
    flex-direction: column;
    //gap: 20px;
    width: 100%;
    max-width: 500px;
    margin: 0 auto 100px;

    .form-group {
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 100%;
      margin-bottom: 20px;
      position: relative;

      &:before {
        content: "";
        position: absolute;
        display: block;
        top: 19px;
        left: 7.5px;
        width: 13px;
        height: 13px;
        border-radius: 50%;
        background-color: #FF2D2E;
        //transform: translateY(-50%);
        box-shadow: 0 0 2px 2px $main-bg-light-shadow-color;
        animation: round-puls 1s infinite;
      }

      .login-form_errmess {
        display: inline-block;
        color: #FF2D2E;
      }

      input, select {
        display: block;
        border: 1px solid #FF2D2E;
        padding: 15px 15px 15px 30px;
        outline: none;
        border-radius: 10px;
        width: 100%;
        color: $text-dark-color;
        font-weight: 700;
        font-size: 16px;


        &::placeholder {
          color: $text-light-dark-color;
          font-weight: 700;
          font-size: 16px;
        }
      }
    }

    .login-form_btn {
      display: inline-block;
      width: max-content;
      margin-top: 30px;
      margin: 0 auto;
      text-transform: uppercase;
    }

  }

  &.change-pass-form {
    margin: 0 auto;
  }

  &.change-account-info-form, &.signup-form {
    margin: 0 auto;

    .form-group {
      &:before {
        content: none;
      }


      input, select {
        padding: 15px;
      }
    }

  }

  &.signup-form {
    align-items: center;
    margin-bottom: 100px;

    .login-link {
      margin-top: 10px;
      color: #ff2d2e;
      text-decoration: underline;
      font-size: 18px;
    }

    .form-group {
      label {
        font-size: 18px;
        color: #fff;
      }
    }
  }

  &.user-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;

    .form-group {
      width: 100%;
    }

    input {
      width: 100%;
    }

    .user-form_btn {
      margin-top: 30px;
    }

  }
}

@keyframes round-puls {
  0% {
    box-shadow: 0 0 2px 2px $main-bg-light-shadow-color;
  }
  50% {
    box-shadow: 0 0 1px 1px $main-bg-light-shadow-color;
  }
  100% {
    box-shadow: 0 0 2px 2px $main-bg-light-shadow-color;
  }
}