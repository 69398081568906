.userTable {
  overflow-x: auto;
  width: 100%;

  table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;

    th {
      background-color: #f2f2f2;
      border: 1px solid #ddd;
      padding: 8px;
      text-align: left;
      white-space: nowrap;
    }

    td {
      border: 1px solid #ddd;
      border-bottom: 1px solid #FF2D2E;
      padding: 8px;
      white-space: nowrap;
    }

    .userTableTDName {
      color: blue;
      text-decoration: underline;
    }

    .userTableTDEvent {
      color: blue;
      text-decoration: underline;
      cursor: pointer;
    }

    .tdDate {
      text-align: center;
    }

    tr:nth-child(odd) {
      background-color: #f9f9f9;
    }

    tr:nth-child(even) {
      background-color: #f9f9f9;
    }
  }

}