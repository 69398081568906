@import "../base/var";

.page-profile {
  margin-bottom: 100px;

  .page-content {
    margin-top: 50px;
  }

  .profile-info {
    display: flex;
    flex-direction: column;

    .top {
      display: flex;
      flex-direction: column;
      gap: 20px;

      @media (min-width: 576px) {
        flex-direction: row;
      }

      .user_photo {
        background: #131313;
        border: 1px solid #FF2D2E;
        width: 240px;
        height: max-content;
        //padding: 20px 20px 0 20px;
        overflow: hidden;
        border-radius: 20px;
        margin: 0 auto;

        @media (min-width: 576px) {
          margin: 0;
        }

        img {
          object-fit: contain;
          width: 100%;
          display: block;
        }

      }

      .user_awards {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;

        @media (min-width: 576px) {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
        }

        .award {
          width: 70px;
          height: auto;

          @media (min-width: 576px) {
            width: 100px;
          }

          img {
            object-fit: contain;
            width: 100%;
            display: block;
          }
        }

      }

    }

    .info {
      display: block;
      position: relative;
      margin-top: 20px;
      color: #fff;
      font-weight: 600;
      font-size: 16px;
      padding-left: 25px;

      &.start-date {
        margin-bottom: 20px;
      }

      &.email {
        margin-top: 50px;
      }

      &:before {
        content: "";
        position: absolute;
        display: block;
        top: 50%;
        left: 0;
        width: 13px;
        height: 13px;
        border-radius: 50%;
        background-color: #FF2D2E;
        transform: translateY(-50%);
        box-shadow: 0 0 2px 2px $main-bg-light-shadow-color;
      }

      @media (min-width: 576px) {
        font-size: 22px;
      }
    }

  }

  .change-account-info-btn {
    margin-top: 50px;
  }

  .change-pass-btn {
    margin-top: 20px;
  }

  .logout {
    margin-top: 20px;
  }

}