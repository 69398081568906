.modalBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalContent {
  padding: 20px;
  background-color: #fff;
  border: 1px solid #FF2D2E;
  border-radius: 10px;
  z-index: 1001;
  max-width: 550px;
  width: 90%;
  max-height: 80%;
  overflow-y: auto;
  position: relative;
  color: #131313;
  font-size: 16px;
  font-weight: 500;
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.modalBody {
  margin-top: 20px;
}
